.site-footer-content {
    position: relative;
    padding-top: rem(30);
    background: $color__background-footer;
    box-shadow: $box-shadow__standard;
}

#colophon {
    padding: $padding__site;
    color: $color__text-header;
    background: $color__background-header;

    @include bp-up(lg) {
        padding: rem(10) $padding__site;
    }

    .moduletable {
        margin-bottom: 0;
        text-align: center;

        @include bp-up(lg) {
            text-align: left;
        }
    }

    .sep {
        @include bp-down(lg) {
            display: none;
        }
    }

    p {
        display: block;
        margin: 0;

        @include bp-up(lg) {
            display: inline;
        }
    }

    a {
        display: block;
        color: inherit;

        @include bp-up(lg) {
            display: inline;
        }
    }
}
