.sidebar {
    padding-top: rem(50);
    
    @include bp-up(lg) {
        padding-top: rem(57);
    }

    .moduletable_news {
        padding-bottom: rem(30);
    }
}

.moduletable_events {
    .newsflash_events,
    .newsflash-horiz_events {
        margin: 0 rem(10);
    }
}
