// Z-Index
@function zindex($idx: default) {
    @if map-has-key($z-index, $idx) {
        @return map-get($z-index, $idx);
    }

    @warn "zindex: Unknown `#{$idx}` in $z-index. "+"Available z-index values are: #{map-key($z-index)}.";
    @return null;
}

// Returns the font size in rem based on the base font size
@function rem($pxSize, $base: $browser-context) {
    $remSize: $pxSize / $base;
    @return #{$remSize}rem;
}
