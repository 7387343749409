.menu-card {

    &:not(.collapsible) {
        padding-top: 1.5em;
    }

    .menu-type,
    h3 {
        position: relative;
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: .4px;
        padding: rem(10) rem(44) rem(10) rem(10);
        margin: .5em 0 0;
        color: $color__text-header;
        background-color: $color__background-header;
        border-radius: 3px;
    }

    .menu-entries,
    ul {
        list-style-type: none;
        margin-bottom: 0;
        padding: 0;
    }

    ul li,
    .menu-entry {
        @include bp-up(md) {
            display: flex;
            align-items: flex-end;
            flex-wrap: wrap;
        }

        &:last-child {
            margin-bottom: 0;
        }

        p {
            // margin-bottom: .5em;
            line-height: 1.2em;
        }

        &__price,
        p:last-child {
            display: flex;
            position: relative;
            margin-bottom: 1em !important;
            text-align: right;

            @include bp-up(md) {
                flex: 1;
            }

            &::before {
                flex: 1;
                position: relative;
                bottom: 3px;
                margin: 0 rem(5);
                content: '';
                margin-right: rem(5);
                border-bottom: 2px dotted $color__background-header;
            }
        }

        span,
        &__extra {
            display: block;
            font-size: 90%;
            font-style: italic;
            opacity: .9;
            
            @include bp-up(md) {
                flex: 0 0 100%;
                margin-top: -.5em;
                order: 1;
            }
        }
    }

    > ul > li,
    > .menu-entries > .menu-entry {
        padding: 1em .5em 2.5em;

        &:nth-child(even) {
            background-color: lighten($color__background-content, 2%);
        }

        &:nth-child(odd) {
            background-color: darken($color__background-content, 5%);
        }
    }

    ul ul {
        padding-left: 1em;
        margin: 0;

        @include bp-up(md) {
            flex: 0 0 100%;
            order: 2;
        }
    }
}

.menu-card-notice {
    font-size: 90%;
    font-style: italic;
    opacity: .9;
}

body[class^=speisekarte] .items-row .headline,
body[class^=speisekarte] .items-row h2 {
    position: relative;

    &::before {
        content: '';
        display: block;
        width: 70%;
        margin: 0 auto 30px;
        height: 1px;
        background-color: transparentize($color__background-news, .5);
    }
}


// Accordion styles
.js .menu-card.collapsible {
    .menu-type:hover {
        cursor: pointer;
    }

    .menu-type:before {
        position: absolute;
        right: .9375rem;
        top: 50%;
        content: "";
        width: 1.5rem;
        height: 1.5rem;
        background-image: url('../images/baseline-expand_more-24px.svg');
        transform: translateY(-50%);
        transform-origin: center center;
        transform-style: preserve-3d;
    }

    .menu-type.active:before {
        transform: translateY(-50%) scaleY(-1);
    }

    .menu-entries {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s cubic-bezier(.45, 0, 0, 1);
    }

    .collapse {
        float: right;
        border: 2px solid $color__background-header;
        background-color: transparent;
        color: $color__text-main;
        padding: rem(5) rem(10);
        margin-bottom: 1em;
        border-radius: 3px;
        cursor: pointer;
        transition: background-color .3s ease-in-out, color .3s ease-in-out;
        overflow: hidden;

        &:hover,
        &:focus {
            background-color: rgba(204, 204, 204, .7);
        }
    }
}
