$header__width: 20em;
$sidebar__width: $header__width;

$padding__site: 30px;
$padding__site-lg: 120px;

$parallax__banner-height: 50vh;
$parallax__banner-height-small: calc(0.8 * #{$parallax__banner-height});

$size__site-nav-max-width: 1080px;
$size__mobile_site-nav-max-width: 600px;
$size__read-content-max-width: 1080px;
$size__paragraph-max-width: 30rem;

$breakpoint__main-menu-expand: lg;
