@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url('../fonts/open-sans-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/open-sans-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/open-sans-v15-latin-regular.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: local('Open Sans Bold'), local('OpenSans-Bold'),
    url('../fonts/open-sans-v15-latin-700.woff2') format('woff2'),
    url('../fonts/open-sans-v15-latin-700.woff') format('woff'),
    url('../fonts/open-sans-v15-latin-700.ttf') format('truetype');
}

@font-face {
    font-family: 'Great Vibes';
    font-style: normal;
    font-weight: 400;
    src: local('Great Vibes Regular'), local('GreatVibes-Regular'),
    url('../fonts/GreatVibes-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Tangerine';
    font-style: normal;
    font-weight: 400;
    src: local('Tangerine Regular'), local('Tangerine-Regular'),
    url('../fonts/Tangerine-Regular.ttf') format('truetype');
}
