.widget-area {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;

    @include bp-up(sm) {
        flex-flow: row wrap;
    }

    h3 {
        font-size: 1.2em;
    }

    .moduletable {
        flex: 0 1 45%;
        margin-bottom: rem(30);

        @include bp-up(lg) {
            flex: 0 1 30%;
        }

        &.map {
            flex: 0 0 100%;
            order: 100;
        }

        p:last-of-type {
            margin-bottom: 0;
        }
    }
       
    ul,
    ol {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li a {
            text-transform: uppercase;
            font-weight: normal;
            line-height: 1.8em;

            &:hover,
            &:focus {
                font-weight: bold;
                text-decoration: none;
            }
        }
    }
}
