@import "font-faces";

html {
    font-size: 14px;

    @include bp-up(md) {
        font-size: 16px;
    }

    @include bp-up(xxl) {
        font-size: 18px;
    }
}

body,
button,
input,
select,
optgroup,
textarea {
	color: $color__text-main;
    font-family: $font__main;
	@include font-size(1);
    line-height: $font__line-height-body;
}

@import "headings";

@import "copy";
