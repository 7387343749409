.clearfix:before,
.clearfix:after,
.site-header:before,
.site-header:after,
.site-content:before,
.site-content:after,
.site-footer:before,
.site-footer:after {
    content: "";
    display: table;
    table-layout: fixed;
}
