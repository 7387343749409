// Utility Mixins
@import "mixins-util";

// Rem output with px fallback
@mixin font-size($sizeValue: 1) {
	font-size: ($sizeValue * 16) * 1px;
	font-size: $sizeValue * 1rem;
}

// Let them wrap
@mixin word-wrap() {
    overflow-wrap: break-word;
    word-wrap: break-word;
    // word-break: break-all;
    word-break: break-word;
    // hyphens: auto;
}


// Center block
@mixin center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}


// Clearfix
@mixin clearfix() {
	content: "";
	display: table;
	table-layout: fixed;
}


// Clear after (not all clearfix need this also)
@mixin clearfix-after() {
	clear: both;
}


// Column width with margin
@mixin column-width($numberColumns: 3) {
	width: map-get( $columns, $numberColumns ) - ( ( $columns__margin * ( $numberColumns - 1 ) ) / $numberColumns );
}

@mixin screen-reader-text() {
    border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute !important;
	width: 1px;
	word-wrap: normal !important; /* Many screen reader and browser combinations announce broken words as they would appear visually. */

	&:focus {
		background-color: $color__background-screen;
		border-radius: 3px;
		box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
		clip: auto !important;
		clip-path: none;
		color: $color__text-screen;
		display: block;
		@include font-size(0.875);
		font-weight: bold;
		height: auto;
		left: 5px;
		line-height: normal;
		padding: 15px 23px 14px;
		text-decoration: none;
		top: 5px;
		width: auto;
		z-index: 100000; /* Above WP toolbar. */
	}
}


// Breakpoints
@mixin bp-up($bp) {
    @if map-has-key($breakpoints, $bp) {
        $width: map-get($breakpoints, $bp);
        @media screen and (min-width: #{$width}) {
            @content;
        }
    }
    @else if ends-with($bp, 'px') {
        // Assume it's a pixel size/width
        @media screen and (min-width: #{$bp}) {
            @content;
        }
    }
    @else {
        @warn "bp-up: Breakpoint `#{$bp}` not found in the breakpoints array. "
            + "Available breakpoints are: #{map-keys($breakpoints)}.";
    }
}

@mixin bp-down($bp) {
    @if map-has-key($breakpoints, $bp) {
        $width: map-get($breakpoints, $bp) - 1px;
        @media screen and (max-width: #{$width}) {
            @content;
        }
    }
    @else if ends-with($bp, 'px') {
        // Assume it's a pixel size/width
        @media screen and (max-width: #{$bp}) {
            @content;
        }
    }
    @else {
        @warn "bp-down: Breakpoint `#{$bp}` not found in the breakpoints array. "
            + "Available breakpoints are: #{map-keys($breakpoints)}.";
    }
}

@mixin bp-only($bp) {
    @if map-has-key($breakpoints, $bp) {
        $idx: index(map-keys($breakpoints), $bp);

        // Get min width
        $min-width: null;
        @if map-has-key($breakpoints, nth(map-keys($breakpoints), $idx)) {
            $min-width: map-get($breakpoints, nth(map-keys($breakpoints), $idx));
        }

        // Get max width
        $max-width: null;
        @if map-has-key($breakpoints, nth(map-keys($breakpoints), $idx + 1)) {
            $max-width: map-get($breakpoints, nth(map-keys($breakpoints), $idx + 1)) - 1px;
        }

        @if ($max-width != null) and ($min-width != null) {
            @media screen and (min-width: #{$min-width}) and (max-width: #{$max-width}) {
                @content;
            }
        }
        @else if ($min-width != null) {
            @media screen and (max-width: #{$max-width}) {
                @content;
            }
        }
        @else if ($max-width != null) {
            @media screen and (min-width: #{$min-width}) {
                @content;
            }
        } 
        @else {
            @warn "bp-only: Something went wrong with index: `#{$idx}`. "
                + "Available breakpoints are: #{map-keys($breakpoints)}.";
        }

    }
}


// Components Mixins
@import "mixins-components";
