.moduletable.social {
    .image-title {
        @include screen-reader-text();
    }

    ul {
        li {
            display: inline-block;
            width: rem(50);
            height: rem(50);
            margin-right: rem(20);

            a {
                display: inline-block;
                background-color: $color__text-main;
                transition: background-color .2s ease-in-out;

                &.lgv-facebook:hover,
                &.lgv-facebook:focus {
                    background-color: $color__facebook;
                }
                &.lgv-youtube:hover,
                &.lgv-youtube:focus {
                    background-color: $color__youtube;
                }
            }

            img {
                max-width: 100%;
                height: auto;
            }
        }
    }
}
