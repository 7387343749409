html,
body {
    height: 100%;
}

.site {
    min-height: 100%;
}

.site {
    display: flex;
    flex-direction: column;
}

.site-content-container {
    width: 100%;
    flex: 1 0 auto;
}
