#contenttop {
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    background: $color__background-quickbook;
    color: $color__text-quickbook;


    // Hide and show content depending on Javascript
    .js & .quickbook-no-js {
        display: none;
    }

    .no-js & #d21-quickbook {
        display: none;
    }

    .moduletable {
        margin: 0;
    }
}

#d21-quickbook {
    position: relative;
    height: rem(49);
    min-height: auto;
    max-width: 250px;
    margin: 0 0 0 auto;
    padding: 0;
    font-size: rem(15);
    z-index: zindex(default);

    @include bp-up(lg) {
        max-width: $size__read-content-max-width;
        cursor: default;

        .picker__holder,
        #d21_tooltip {
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &::before,
    &::after {
        position: relative;
        line-height: rem(49);
        color: inherit;
    }

    &::before {
        display: block;
        font-family: $font__main;
        padding-left: rem(15);
        content: 'Zimmer buchen';
        cursor: pointer;

        @include bp-down(250px) {
            content: 'Buchen';
        }

        @include bp-up(lg) {
            float: left;
            padding: 0 rem(15) 0 0;
            cursor: default;
        }
    }

    &::after {
        position: absolute;
        right: rem(15);
        top: 50%;
        width: rem(24);
        height: rem(24);
        content: '';
        color: white;
        background-image: url('../images/baseline-expand_more-24px.svg');
        transform: translateY(-50%);
        transform-origin: center center;
        transform-style: preserve-3d;

        @include bp-up(lg) {
            display: none;
        }
    }

    &.open {
        &::after {
            transform: translateY(-50%) scaleY(-1);

        }

        form {
            display: block;
        }
    }


    form {
        display: none;
        padding: rem(15);
        background: $color__background-quickbook;

        @include bp-up(lg) {
            display: flex !important;
            flex-flow: row wrap;
            justify-content: space-between;
            padding: .5rem 0;
            background: transparent;
        }

        div[id*="-container"],
        div.halfWidth[id*="-container"] {
            width: 100%;
            float: none;

            @include bp-up(lg) {
                width: 25%;
                margin: 0;
            }
        }

        .marginRight {
            margin-right: 0 !important;
        }

        .picker {

            &__holder {
                max-width: 100%;
                margin-bottom: rem(20);
            }

            &__box {
                padding-bottom: rem(20) !important;
            }
        }
    }

    #d21-headline-container {
        display: none !important;
    }

    #d21-submit-container input:not(.d21-pulse-btn),
    .d21-pulse-btn,
    #d21-submit-container input {
        background-color: $color__link-hover;
        background: $color__link-hover;
    }

    .d21-pulse-btn {
        animation-name: none;
    }

    select {
        cursor: pointer;
    }

    #d21_tooltip {
        top: 100%;
        right: 0;
        left: unset;
        margin-bottom: rem(20);

        &::before {
            display: none;
        }
    }

    .field,
    .hasDatepicker,
    select {
        background-color: rgb(255, 255, 255);
    }

    #d21-avail-result-container {

        @include bp-up(lg) {
            width: 24.5%;
            order: 1;
            margin: .5rem rem(-15) 0 auto !important;
            padding: 0 rem(15) rem(15);
            background-color: opacify($color__background-quickbook, .1) !important;
        }

        p {
            line-height: 1.8rem;
        }

        .bigger {
            display: block;
            left: unset;
            font-size: 1.6em;
        }
    }

    #d21-submit-container {
        @include bp-up(lg) {
            width: 21%;
        }

        #d21-book-button:not(.d21-pulse-btn) {
            cursor: default;
        }
    }

    #d21-load-overlay {
        background-color: transparent;
    }
}
