#contentbottom {
    padding-top: rem(20);
    padding-bottom: rem(40);
    text-align: center;

    video {
        max-width: 100% !important;
        height: auto !important;
    }

}
