.slideshow-container {
    position: relative;
    height: 30vh;
    margin-top: rem(56);

    @include bp-up(md) {
        height: 40vh;
    }

    @include bp-up(lg) {
        height: 45vh;
    }

    .no-js & {
        @include bp-down(lg) {
            margin-top: 0;
        }
    }
}

.slideshow-viewport {
    position: relative;
    width: 100%;
    max-width: $size__read-content-max-width;
    height: 100%;
    margin: 0 auto;
    background: $color__background-content;
    box-shadow: $box-shadow__standard;
    
    #slnext,
    #slprev {
        position: absolute;
        top: 40%;
    }

    #slnext {
        right: 0;
    }
}

.slideshow-inner {
    position: relative;
    height: 100%;

    img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
