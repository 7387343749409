// All z-index values throughout the site go here!
$z-index: (
  background: -10,
  ground: 0,
  default: 1,
  header: 600,
  overlay: 1001,
  mainNav: 1200,
  alwaysOnTop: 99999
);
