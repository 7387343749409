// Link disguised as a button with a hover effect
@mixin button-link() {
    display: inline-block;
    position: relative;
    max-width: 100%;
    margin-top: 1.5em;
    padding: 1em 1.5em;
    color: $color__link;
    background: $color__background-body;
    border: 1px solid $color__link;
    border-radius: $border-radius__button;
    font-weight: bold;
    transition: all .15s ease-out;
    cursor: pointer;

    span:not(.screen-reader-text) {
        position: relative;
        left: 0;
        transition: all .2s ease-out;
    }

    i::before {
        position: absolute;
        top: 48%;
        right: 30px;
        opacity: 0;
        content: '→';
        transform: translateY(-50%) scale(1.2);
        transition: all .25s ease-out;
    }

    &:hover,
    &:focus {
        background: $color__link;
        color: $color__background-body;

        span:not(.screen-reader-text) {
            left: -10px;
        }

        i::before {
            opacity: 1;
            right: 10px;
        }
    }
}

@mixin accent-line($px-height: 1, $px-width: 100) {
    position: relative;

    &::after {
        content: "";
        position: absolute;
        bottom: -20%;
        left: 0;
        height: rem($px-height);
        width: rem($px-width);
        background: $color__background-header;
        opacity: .4;
    }
}
