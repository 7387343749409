.site {
    @include bp-up(sm) {
        padding: 0 $padding__site $padding__site;
    }
}

#content60 {
    padding-top: 1rem;
}

.cols-2 .item {
    width: 100%;
    float: none;
}

.site-header,
.site-content,
.sidebar,
.widget-area,
.content-component {
    padding: 0 $padding__site;
}

#contenttopleft {
    padding: 0;
}

.site-content-container,
.site-footer-content {
    max-width: $size__read-content-max-width;
    margin: 0 auto;
}

.site-content-container {
    position: relative;
    background: $color__background-content;
    box-shadow: $box-shadow__standard;

    @include bp-up(lg) {
        display: flex;
        justify-content: space-between;
        flex-flow: row wrap;

        .site-content,
        .sidebar {
            margin: 0;
        }

        .site-content {
            flex: 0 1 72%;
        }

        .sidebar {
            flex: 0 1 28%;
        }

        .content-component#contentbottom {
            flex: 0 0 100%;
        }
    }
}

.site::before {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    //Photo by Michael Mroczek on Unsplash (https://unsplash.com/photos/xVKEZ9wVIYM)
    // background: url('../images/michael-mroczek-440382-unsplash.jpg');
    // background-size: cover;
    // background-color: rgb(112, 78, 64);
    z-index: zindex(background);
}

.site-content,
.welcome-page .sidebar {

    @include bp-down(lg) {
        &::after {
            display: block;
            margin: 0 auto;
            content: '';
            width: 50%;
            height: rem(2);
            background: darken($color__background-content, 10%);
            border-bottom: 1px solid darken($color__background-content, 30%);
        }
    }
}

.content-component#contentbottom {
    @include bp-up(lg) {
        &::before {
            display: block;
            margin: 0 auto;
            content: '';
            width: 50%;
            height: rem(2);
            background: darken($color__background-content, 10%);
            border-bottom: 1px solid darken($color__background-content, 30%);
        }
    }
}
