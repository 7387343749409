$color__background-body: #fff;
$color__background-screen: #f1f1f1;
$color__background-hr: #ccc;
$color__background-button: #e6e6e6;
$color__background-pre: #eee;
$color__background-ins: #fff9c0;
$color__background-header: rgb(0, 51, 0);
$color__background-content: rgb(244, 241, 232);
$color__background-footer: darken($color__background-content, 8%);
$color__background-quickbook: rgba(0, 51, 0, .8);
$color__background-news: rgba(0, 51, 0, .8);

$color__text-main: #404040;
$color__text-main-inverted: #fff;
$color__text-sub: transparentize($color__text-main, .4);
$color__text-muted: rgb(237, 237, 237);
$color__text-caption: transparentize($color__text-main, .3);
$color__text-screen: #21759b;
$color__text-input: #666;
$color__text-input-focus: #111;
$color__text-header: #fff;
$color__text-footer: $color__text-header;
$color__text-quickbook: rgb(255, 255, 255);
$color__text-news: rgb(255, 255, 255);

$color__link: royalblue;
$color__link-background: rgb(36, 36, 37);
$color__link-visited: purple;
$color__link-hover: rgb(189, 162, 121);

$color__border-button: #ccc #ccc #bbb;
$color__border-button-hover: #ccc #bbb #aaa;
$color__border-button-focus: #aaa #bbb #bbb;
$color__border-input: #ccc;
$color__border-abbr: #666;

$color__facebook: rgb(72, 103, 170);
$color__twitter: rgb(29, 161, 242);
$color__youtube: rgb(225, 43, 40);
