pre {
    width: calc(100% + 2*#{$padding__site});
    margin: 1rem 0 1.6rem -#{$padding__site};
    padding: $padding__site;
	background: $color__background-pre;
	font-family: $font__pre;
	@include font-size(0.9375);
	line-height: $font__line-height-pre;
	margin-bottom: 1.6em;
	min-width: 100%;
    overflow: auto;
    
    @include bp-up(md) {
        width: calc(100% + 2*#{$padding__site-lg});
        margin: 1rem 0 1.6rem -#{$padding__site-lg};
        padding: $padding__site-lg;
    }
}

code {
	font-family: $font__code;
	@include font-size(0.9375);
}