/* Template: OS-KOEHNE */
/* v1.0 | (C) Oliver Sieke 2012 */

html,
body,
div,
span,
applet,
object,
iframe,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    /*  background: transparent; */
}

* {
    margin: 0;
    padding: 0
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

/* remember to define focus styles! */
:focus {
    outline: 0;
}

/* remember to highlight inserts somehow! */
ins {
    text-decoration: none;
}

del {
    text-decoration: line-through;
}

html body span.clr,
html body div.clr,
html body li.clr,
html body dd.clr {
    background: none;
    border: 0;
    clear: both;
    display: block;
    float: none;
    font-size: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    visibility: hidden;
    width: 0;
    height: 0;
}

a img {
    border: 0;
}

a,
a:visited {
    font-weight: bold;
    color: #003300;
    text-decoration: none;
}

a:hover,
a:active {
    color: #646961;
    text-decoration: underline;
}

/*----------------------------------------------------- */
body {
    /*  padding: 20px 0px 0 0px;*/
    color: #000000;
    /*  background:#fcf7e0;*/
    font-size: 100%;
    font-family: "Trebuchet MS", 'Lucida Grande', Verdana, Arial, Sans-Serif;
    background: url("../images/wallpaper_r00_75x75.png");

}

#allwrap {
    width: auto;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

#footerwrap {
    margin: 0;
    padding: 0;
    border-radius: 25px;
}

#footer {
    padding: 10px 0 10px 0;
    text-align: center;
    position: relative;
    /*  background: url("../images/bg.png") repeat-y;*/
    background-color: #dfe4da;
}

#footerending {
    /*  background: url("../images/bottom.png") no-repeat;*/
    background-color: #d4dbcd;
    text-decoration: none;
    border-radius: 0px 0px 10px 10px;
}

#footerending a:visited {
    color: #333;
}

#footerending a:hover {
    color: #fff;
}


#pathway {
    font-family: Arial, Helvetica, sans-serif;
    text-align: left;
    padding: 7px 0 0 0;
    font-size: 11px;
    font-weight: normal;
}

#topmenu {
    text-align: left;
    margin: auto 10px 0 0;
    padding: 0;
    text-transform: uppercase;
    font-weight: normal;
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    z-index: 1000;
}

#wrap {
    text-align: left;
    margin: 0 auto;
    padding: 0px 0px 15px 0px;
    background-color: #f4f4f4;
    /*  background: url("../images/bg.png") repeat-y;*/
    background-color: #dfe4da;
}

#nav {
    float: left;
    display: inline;
    padding: 0;
    margin: 5px 15px 0px 15px;
    text-align: left;
}

#nav ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

#nav li {
    list-style-image: none;
    margin: 0px;
}

#nav a {
    color: #4C4C4C;
    display: block;
    font-size: 15px;
    font-weight: bold;
    height: 25px;
    padding-bottom: 3px;
    padding-top: 1px;
    padding-left: 5px;
    text-decoration: none;
    width: 180px;
}

#nav a:link,
#nav a:visited {
    /* background: url("../images/navitem_bg.png") no-repeat; */
}

#nav li a:hover {
    background: url("../images/navitem_bg.png") no-repeat;
}

#nav li a#active {
    background: url("../images/navitem_bg.png") no-repeat;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
dl,
address {
    margin: 0.5em 0;
}

fieldset {
    margin: 0.5em 0;
    padding: 0.5em 10px;
}

form {
    margin: 0;
}

ul,
ol {
    margin: 0 0 0 1em;
    padding: 0 0 0 1em;
    list-style-position: outside;
}

li,
dd {
    margin-left: 0em;
    padding-left: 0;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

/* ==== MODULES =======================================  */
div.moduletable {
    margin-bottom: 20px;
    border: 0px #CCC solid;
    padding: 0px 0px 0px 0px;
}

div.moduletable h3 {
    text-align: left;
    text-transform: uppercase;
    font-weight: normal;
    letter-spacing: 2px;
    margin: 0 0 0.5em 0;
    padding: 0.1em 0 0.1em 0;
}

/* ==== SIDEBAR MODULES =======================================  */
#sidebar div.moduletable {
    margin-bottom: 20px;
    padding: 0px 0px 0px 0px;
}

#sidebar div.moduletable h3 {
    font-family: Verdana, Arial, Helvetica, sans-serif;
    text-align: left;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: normal;
    letter-spacing: 0px;
    margin: 0 0 0.5em 0;
    padding: 4px 0 5px 10px;
    position: relative;
    line-height: 1.2;
}

/* ==== SIDEBAR2 MODULES =======================================  */
#sidebar-2 div.moduletable {
    margin-bottom: 20px;
    padding: 0px 0px 0px 0px;
}

#sidebar-2 div.moduletable h3 {
    font-family: Verdana, Arial, Helvetica, sans-serif;
    text-align: left;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: normal;
    letter-spacing: 0px;
    margin: 0 0 0.5em 0;
    padding: 4px 0 5px 10px;
    position: relative;
    line-height: 1.2;
}

/* ======= FOOTER =======================  */
#footer div.moduletable {
    padding: 0 0 0px 0;
    margin-bottom: 0;
}

#footer div.moduletable h3 {
    display: none;
}

/* ======= Topmenu =========== */
#topmenu div.moduletable h3 {
    display: none;
}

#topmenu .moduletable {
    margin: 0 0px 0 0;
    padding: 0;
    float: right;
}

#topmenu .menu {
    list-style: none;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    text-transform: uppercase;
    letter-spacing: 0px;
    padding: 0;
    margin: 0;
}

#topmenu .menu li a {
    display: block;
    color: #4C4C4C;
    text-decoration: none;
    padding: 6px 14px 6px 14px;
}

#topmenu .menu li a:hover {
    color: #fff;
    text-decoration: none;
}

#topmenu .menu li {
    float: left;
    background: url("../images/bg_topmenu.png") right no-repeat;
    border-radius: 5px;
    padding: 0;
}

#topmenu .menu_os-tabbed,
#topmenu .joomla-nav_os-tabbed {
    list-style: none;
    font-weight: normal;
    font-size: 14px;
    line-height: 35px;
    text-transform: uppercase;
    letter-spacing: 0px;
    padding: 0;
    margin: 0;
}

#topmenu .menu_os-tabbed li,
#topmenu .joomla-nav_os-tabbed li {
    float: left;
    margin: 0 0 0 1px;
    padding: 0;
}

#topmenu .menu_os-tabbed a,
#topmenu .joomla-nav_os-tabbed a,
.menu_os-tabbed .separator {
    display: block;
    padding: 0 10px;
    text-decoration: none;
    background: url("../images/bg_topmenu.png") repeat-x;
    border-radius: 5px 5px 0px 0px;
}

#topmenu .menu_os-tabbed a:hover,
#topmenu .joomla-nav_os-tabbed a:hover,
.menu_os-tabbed #current a,
.menu_os-tabbed .active a {
    background: url("../images/bg_topmenu_a.png") repeat-x;
    border-radius: 5px 5px 0px 0px;
}

/* ======= SIDE MENUS ================================  */
.moduletable_menu {
    font-size: 12px;
    padding: 0px 0px 5px 0px;
    margin-bottom: 20px;
}

.moduletable_menu h3 {
    font-family: Verdana, Arial, Helvetica, sans-serif;
    text-align: left;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: normal;
    letter-spacing: 0px;
    margin: 0 0 0.15em 0;
    padding: 4px 0 5px 10px;
    position: relative;
    line-height: 1.2;

    background: none repeat scroll 0 0 #222222;
    border-bottom: 0 solid #444455;
    border-radius: 4px 4px 4px 4px;
    color: #FFFFFF;
}

.moduletable_menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.moduletable_menu li {
    border-bottom: 0px solid #ccc;
    text-transform: uppercase;
}

.moduletable_menu li a {
    display: inline;
    text-decoration: none;
    padding: 0;
}

html>body .moduletable_menu li a {
    width: auto;
}

.moduletable_menu li ul {
    list-style-type: square;
    list-style-position: inside;
}

.moduletable_menu li ul li {
    border-bottom: 0px solid #ccc;
    border-top: 0px solid #ccc;
    padding: 4px 0 2px 1em;
    text-transform: none;
    letter-spacing: 0px;
}

.moduletable_menu li ul li a {
    display: inline;
    text-decoration: none;
    padding: 0;
}

html>body .moduletable_menu li ul li a {
    width: auto;
}

.moduletable_menu li a:hover {}

.moduletable_menu li.current>a {}

/* -- */
.moduletable_events {
    font-size: 12px;
    padding: 0px 0px 5px 0px;
    margin-bottom: 20px;
}

.moduletable_events .events-title {
    text-align: left;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: normal;
    letter-spacing: 0px;
    margin: 0 0 0.15em 0;
    padding: 4px 0 5px 10px;
    position: relative;
    line-height: 1.2;

    background: none repeat scroll 0 0 #222222;
    border-bottom: 0 solid #444455;
    border-radius: 4px 4px 4px 4px;
    color: #FFFFFF;
}

.moduletable_events ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.moduletable_events .article-separator {
    border-top: 1px solid #CDCDCD;
    display: block;
    width: 95%;
    line-height: 12px;
    margin-top: 12px;
    margin-left: 5px;
}

.moduletable_events li {
    border-bottom: 0px solid #ccc;
}

.moduletable_events li a {
    display: inline;
    text-decoration: none;
    padding: 0;
}

html>body .moduletable_events li a {
    width: auto;
}

.moduletable_events li ul {
    list-style-type: square;
    list-style-position: inside;
}

.moduletable_events li ul li {
    border-bottom: 0px solid #ccc;
    border-top: 0px solid #ccc;
    padding: 4px 0 2px 1em;
    text-transform: none;
    letter-spacing: 0px;
}

.moduletable_events li ul li a {
    display: inline;
    text-decoration: none;
    padding: 0;
}

html>body .moduletable_events li ul li a {
    width: auto;
}

.moduletable_events li a:hover {}

.moduletable_events li.current>a {}


/* -- */
.moduletable_eventsbottom {
    /*   font-size:14px; */
    padding: 0px 0px 5px 0px;
    margin-bottom: 20px;
}

.moduletable_eventsbottom h3 {
    text-align: left;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: normal;
    letter-spacing: 0px;
    margin: 0 0 0.15em 0;
    padding: 4px 0 5px 10px;
    position: relative;
    line-height: 1.2;

    background: none repeat scroll 0 0 #222222;
    border-bottom: 0 solid #444455;
    border-radius: 4px 4px 4px 4px;
    color: #FFFFFF;
}

.moduletable_eventsbottom ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.moduletable_eventsbottom .article-separator {
    border-top: 1px solid #CDCDCD;
    display: block;
    width: 95%;
    line-height: 12px;
    margin-top: 12px;
    margin-left: 5px;
}

.moduletable_eventsbottom li {
    border-bottom: 0px solid #ccc;
}

.moduletable_eventsbottom li a {
    display: inline;
    text-decoration: none;
    padding: 0;
}

html>body .moduletable_eventsbottom li a {
    width: auto;
}

.moduletable_eventsbottom li ul {
    list-style-type: square;
    list-style-position: inside;
}

.moduletable_eventsbottom li ul li {
    border-bottom: 0px solid #ccc;
    border-top: 0px solid #ccc;
    padding: 4px 0 2px 1em;
    text-transform: none;
    letter-spacing: 0px;
}

.moduletable_eventsbottom li ul li a {
    display: inline;
    text-decoration: none;
    padding: 0;
}

html>body .moduletable_eventsbottom li ul li a {
    width: auto;
}

.moduletable_eventsbottom li a:hover {}

.moduletable_eventsbottom li.current>a {}


/* ========== content ======================*/
div#content p {
    // text-align: justify;
}

a img {
    border: 0;
}

/* =========== overlib ========================*/

.ol-foreground {
    background-color: #f6f6f6;
}

.ol-background {
    background-color: #666;
}

.ol-textfont {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 10px;
}

.ol-captionfont {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    color: #f6f6f6;
    font-weight: bold;
}

.ol-captionfont a {
    color: #0B55C4;
    text-decoration: none;
    font-size: 12px;
}

.ol-closefont {}

/* ==============  Featured articles, Category blog  =====================7 */
.blog-featured {
    padding: 0;
}

.items-leading {
    padding: 0;
    margin-bottom: 10px
}

.row-separator {
    display: block;
    clear: both;
    margin: 0;
    border: 0px;
    height: 1px
}

.item-separator {
    border-top: 1px solid #CDCDCD;
    display: block;
    width: 95%;
    line-height: 12px;
    margin-top: 12px;
    margin-left: 5px;
}

.cols-1 {
    display: block;
    float: none !important;
    margin: 0 !important;
}

.cols-2 .column-1 {
    width: 47%;
    float: left;
    margin: 0;
}

.cols-2 .column-2 {
    width: 47%;
    float: right;
    margin: 0;
}

.cols-3 .column-1 {
    float: left;
    width: 30%;
    padding: 0;
    margin-right: 5%;

}

.cols-3 .column-2 {
    float: left;
    width: 30%;
    margin-left: 0;
    padding: 0;
}

.cols-3 .column-3 {
    float: right;
    width: 30%;
    padding: 0;
}

.cols-4 .column-1 {
    width: 22%;
    float: left;
}

.cols-4 .column-2 {
    width: 22%;
    margin-left: 4%;
    float: left;
}

.cols-4 .column-3 {
    width: 22%;
    margin-left: 4%;
    float: left;
}

.cols-4 .column-4 {
    width: 22%;
    margin-left: 0;
    float: right;
}

.items-row {
    margin-bottom: 10px !important;
}

.column-1,
.column-2,
.column-3,
.column-4 {
    padding: 0;
}

.items-more {
    padding: 10px 0px
}

.hidelabeltxt {
    display: none;
}

.item-page {}

/* ================  details  ==================== */
.content_rating {
    font-size: 0.9em;
    color: #889;
}

.content_vote {
    font-size: 0.9em;
    color: #889;
}

.content_vote input {
    font-size: 1em;
}

.article-info {
    margin: 5px 0 6px 0px;
    font-size: 0.8em;
    color: #889;
}

.article-info-term {
    display: none;
}

.article-info dd {
    line-height: 1.4em;
}

/* ===================  icons  ====================== */

ul.actions {
    text-align: left;
    padding: 0;
    margin: 0
}

ul.actions li {
    display: inline
}

ul.actions a {
    text-decoration: none
}

/* =====================  pagenav  ===================== */
div.pagenavcounter {
    margin: 10px 0 0 0;
    padding: 0;
    text-align: left;
}

ul.pagenav {
    list-style-type: none;
    padding: 0;
    margin-top: 10px;
    text-align: center;
}

ul.pagenav li {
    display: inline;
    padding: 0px 4px;
    margin: 0;
}

ul.pagenav li {
    line-height: 2em;
}

ul.pagenav li a {
    display: inline;
    text-decoration: none
}

/* ====================  pagination  ================== */

div.pagination {
    margin: 10px 0;
    padding: 10px 0;
    text-align: center;
}

div.pagination ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

div.pagination li {
    display: inline;
    padding: 0;
    text-align: left;
    margin: 0 5px;
}

span.pagenav {}

div#content div.pagination p.counter {
    font-weight: bold;
    text-align: center;
}

/* ====================  Breadcrumbs  ================== */
.breadcrumb {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.breadcrumb li {
    display: inline-block;
}

/* ===================== Archive articles =================== */
div.archive fieldset {
    padding: 6px 0;
    border: 0;
}

ul#archive-items {
    margin: 20px 0 20px 0px;
    list-style-type: none;
    padding: 0
}

/* ===================== List all Categories =================== */
div.categories-list ul {
    list-style-type: none;
    margin: 10px 0 0 0;
    padding: 0;
}

div.categories-list ul ul {
    list-style-type: square;
    margin: 0;
    padding: 0 0 0 25px;
}

div.categories-list ul ul ul {
    list-style-type: disc;
    margin: 0;
    padding: 0 0 0 25px;
}

div.categories-list ul ul ul ul {
    list-style-type: circle;
    margin: 0;
    padding: 0 0 0 25px;
}

div.categories-list ul ul ul ul ul {
    list-style-type: square;
    margin: 0;
    padding: 0 0 0 25px;
}

div.categories-list dl dt,
div.categories-list dl dd {
    display: inline;
    font-style: italic;
}

dl.article-count dt,
dl.article-count dd {
    display: inline;
    font-style: italic;
}

div.categories-list ul li span.item-title {
    font-size: 1.3em;
    font-weight: bold;
}

div.categories-list ul li ul li span.item-title {
    font-size: 1.25em;
    font-weight: bold;
}

div.categories-list ul li ul li ul li span.item-title {
    font-size: 1.15em;
    font-weight: bold;
}

div.categories-list ul li ul li ul li ul li span.item-title {
    font-size: 1.1em;
    font-weight: bold;
}

div.category-desc img.image-left {
    float: left;
    padding-right: 5px;
}

div.category-desc img.image-right {
    float: right;
    padding-left: 5px;
}

/* ===================== Subategories =================== */
div.cat-children {
    margin: 15px 0 0 0;
    padding: 0;
}

div.cat-children ul {
    list-style-type: none;
    margin: 10px 0 0 0;
    padding: 0;
}

div.cat-children ul ul {
    list-style-type: square;
    margin: 0;
    padding: 0 0 0 25px;
}

div.cat-children ul ul ul {
    list-style-type: disc;
    margin: 0;
    padding: 0 0 0 25px;
}

div.cat-children ul ul ul ul {
    list-style-type: circle;
    margin: 0;
    padding: 0 0 0 25px;
}

div.cat-children ul ul ul ul ul {
    list-style-type: square;
    margin: 0;
    padding: 0 0 0 25px;
}

div.cat-children ul li span.item-title {
    font-size: 1.2em;
    font-weight: bold;
}

div.cat-children ul li ul li span.item-title {
    font-size: 1.15em;
    font-weight: bold;
}

div.cat-children ul li ul li ul li span.item-title {
    font-size: 1.10em;
    font-weight: bold;
}

div.cat-children ul li ul li ul li ul li span.item-title {
    font-size: 1.05em;
    font-weight: bold;
}

div.cat-children ul li dl dt,
div.cat-children ul li dl dd {
    display: inline;
    font-style: italic;
}

/* ===================== Category list =================== */
table.category {
    font-size: 1em;
    margin: 10px 0px 20px 0px;
    width: 99%;
}

table.category th {
    text-transform: uppercase;
    text-align: left;
    font-weight: bold;
    font-size: 1.1em;
}

table.category td {
    text-align: left;
    padding-top: 5px;
}

table.category th img {
    padding-left: 5px;
}

/* ===================== Login/Logout =================== */
div.login fieldset {
    border: 0;
}

div.login-fields {
    margin: 10px 0 0 0;
}

div.login-fields label {
    display: inline-block;
    width: 9em;
}

div.login-fields input#username,
div.login-fields input#password {
    width: 173px;
}

div.login fieldset button.button {
    margin-top: 10px;
}

div.logout button.button {
    margin-top: 10px;
}

/* ===================== Login module =================== */
form#login-form fieldset {
    border: 0;
}

form#login-form fieldset label {
    display: inline-block;
    width: 9em;
}

form#login-form fieldset input#modlgn-passwd {
    width: 131px;
}

form#login-form fieldset input#modlgn-username {
    width: 131px;
}

/* =====================  User profile ===================== */

#users-profile-core,
#users-profile-custom {
    margin: 10px 0 15px 0;
    padding: 15px;
}

#users-profile-core dl,
#users-profile-custom dl {
    margin: 0;
    padding: 0;
}

#users-profile-core dt,
#users-profile-custom dt {
    float: left;
    width: 22em;
    padding: 3px 5px 3px 0;
}

#users-profile-core dd,
#users-profile-custom dd {
    padding: 3px 0;
}

#member-profile fieldset {
    margin: 10px 0 15px 0;
    padding: 15px;
}

#users-profile-core legend,
#users-profile-custom legend,
.profile-edit legend {
    font-weight: bold;
    font-size: 1.1em;
}

.profile-edit form#member-profile fieldset dd {
    padding: 5px 0;
}

.profile-edit form#member-profile fieldset dd input {
    width: 17em;
}

.profile-edit form#member-profile fieldset dd select {
    width: 17em;
    height: 24px;
}

.profile-edit form#member-profile fieldset dt {
    padding: 5px 5px 5px 0;
    width: 22em;
    float: left;
}

.profile-edit span.optional {
    font-size: 0.9em;
}

/* =====================  Registration ===================== */
div.registration form#member-registration fieldset {
    margin: 10px 0 15px 0;
    padding: 15px 0 0 10px;
    border: 0;
}

div.registration form#member-registration fieldset legend {
    font-weight: bold;
    font-size: 1.2em;
}

div.registration form#member-registration fieldset dd {
    padding: 0 0 5px 0;
}

div.registration form#member-registration fieldset dd input {
    width: 17em;
}

div.registration form#member-registration button {
    margin-left: 10px;
}

div.remind form#user-registration fieldset {
    border: 0;
}

div.remind form#user-registration button {
    margin-left: 10px;
}

div.reset form#user-registration fieldset {
    border: 0;
}

div.reset form#user-registration button {
    margin-left: 10px;
}

/* =====================  Single newsfeed ===================== */
div.newsfeed ol {
    margin-top: 10px;
}

div.newsfeed ol li a {
    font-weight: bold;
}

div.newsfeed ol li div a,
div.newsfeed ol li div ol li a {
    font-weight: normal;
}

/* =====================  Search results ===================== */
dl.search-results dt.result-title {
    font-weight: bold;
    padding-top: 10px;
}

dl.search-results dd.result-text {
    padding-top: 5px;
}

dl.search-results dd.result-created {
    padding-top: 5px;
    font-size: 0.8em;
    color: #889;
}

div.search form#searchForm fieldset.word,
div.search form#searchForm fieldset.phrases,
div.search form#searchForm fieldset.only {
    border: 1px solid #ccc;
}

div.search form#searchForm fieldset.word button.button {
    margin: 0px 0 0 2px;
    padding: 0px 3px;
    font-size: 12px;
    line-height: 18px;

}

div.search form#searchForm fieldset.phrases div.ordering-box {
    margin-top: 5px;
}

.small {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 10px;
    line-height: 1.2;
}

.highlight {
    font-weight: bold;
}

/* =====================  Single Contact ===================== */
div.contact-form {
    margin-bottom: 10px;
}

div.contact-form label {}

div.contact-form form#contact-form dl {
    margin: 10px 0;
}

div.contact-form form#contact-form dt {
    margin-top: 7px;
}

div.contact-form form#contact-form dd {
    margin-top: 0px;
}

address span {
    display: block
}

div.contact form#contact-form button.button {
    float: none !important;
    clear: left;
    display: block;
    margin: 15px 0 0 0;
}

div.contact dl.tabs {
    float: left;
    margin: 20px 0 0 0;
    padding: 0;
    clear: both;
}

div.contact dl.tabs dt {
    float: left;
    padding: 2px 10px;
    border-left: 1px solid #ccd;
    border-right: 1px solid #ccd;
    border-top: 1px solid #ccd;
    margin-right: 4px;
    background: none;
}

div.contact dl.tabs dt a {
    font-weight: normal;
}

div.contact dl.tabs dt.open {
    background: none;
    border-bottom: 0px solid #ccd;
}

div.contact dl.tabs dt.open a {
    color: #445;
}

div.contact dl.tabs dt h3 {
    margin: 2px 0;
    padding: 0;
}

div.contact div.current {
    clear: both;
    border: 1px solid #ccd;
    padding: 15px 15px;
    margin: 0;
}

div.contact dl.tabs dd {
    clear: both;
    padding: 0;
    margin: 0;
}

/* =====================  List Contacts, Newsfeeds ===================== */
form#adminForm fieldset.filters {
    border: 0;
    padding: 0;
}

/* ===================== tooltip =====================  */
.tip {
    font-size: 0.8em;
    text-align: left;
    padding: 5px;
    max-width: 400px;
    border: 1px solid #ccc;
    background: #fff;
}

.tip-title {
    font-weight: bold;
}

/* ===================== Email to a friend =====================  */
div#mailto-window {
    margin: 15px;
}

form#mailtoForm div.formelm label {
    text-align: right;
    display: inline-block;
    width: 8em;
    padding-right: 5px;
}

form#mailtoForm div.formelm {
    margin: 5px 0;
}

/* ===================== Submit a weblink, Submit an article, Edit article =====================  */
div.edit form#adminForm div.formelm label {
    text-align: left;
    display: inline-block;
    width: 12em;
    padding-right: 5px;
}

div.edit form#adminForm div.formelm {
    margin: 5px 0;
}

div.edit form#adminForm div.formelm-area label {
    text-align: left;
    float: left;
    width: 12em;
    padding-right: 5px;
}

div.edit form#adminForm div.formelm-area {
    margin: 5px 0;
}

div.edit form#adminForm legend {
    font-weight: bold;
    font-size: 1.1em;
}

/* ===================== Statistics module =====================  */
dl.stats-module dt {
    float: left;
    padding: 0 10px 0 0;
    font-weight: bold;
}

dl.stats-module_light dt {
    float: left;
    padding: 0 10px 0 0;
    font-weight: bold;
}

dl.stats-module_dark dt {
    float: left;
    padding: 0 10px 0 0;
    font-weight: bold;
}

/* ===================== Newsflash module =====================  */
div.newsflash p {
    text-align: justify;
}

div.newsflash_light p {
    text-align: justify;
}

div.newsflash_dark p {
    text-align: justify;
}

/* ===================== Wrapper =====================  */
div.contentpane {
    border: 1px solid #ccc;
}

/* ===================== Slideshow =====================  */
a#slprev {
    background: url("../images/previous-white.png") no-repeat scroll left center transparent;
    cursor: pointer;
    float: left;
    height: 50px;
    width: 50px;
}

a#slnext {
    background: url("../images/next-white.png") no-repeat scroll right center transparent;
    cursor: pointer;
    float: right;
    height: 50px;
    width: 50px;
}

/* ===================== System message =====================  */
div#system-message-container ul {
    margin: 0;
}

/* ===================== KÖHNE =====================  */
.ltxt {
    text-align: left;
}

.ltxt2 {
    text-align: left;
}

.rtxt {
    text-align: right;
}

.rtxt2 {
    text-align: right;
}

.skh {
    font-weight: bold;
    letter-spacing: 1px;
    padding-top: 15px;
    padding-bottom: 5px;
    text-decoration: underline;
}

.ppimg {
    background-color: #ffffff;
    padding: 7px;
    margin: 2px;
    border: 1px #333 solid;
}

#header,
#wrap,
#footer,
#footerending {
    margin: 0;
}

/* DIRS Mods */
#d21-headline-container {
    text-align: center;
}

div#d21-quickbook div.halfWidth {
    width: 100%;
    margin: 0 0 6px;
}

#d21-quickbook *:not(.d21-tooltip-box)>i:not(.minus):not(.plus):not(.delete):not(.removeText) {
    background-color: transparent !important;
    color: rgb(0, 0, 0) !important;
}

#d21-quickbook .field,
#d21-quickbook .hasDatepicker,
#d21-quickbook *:not(.D21-Age-Select)>select {
    border: 1px solid rgba(0, 0, 0, .7) !important;
}

#d21-quickbook #d21-submit-container input:not(.d21-pulse-btn) {
    background: #ccc !important;
}

div.picker__holder {
    right: 0 !important;
}

div#d21-quickbook div#d21-avail-result-container {
    background-color: #222222;
}
