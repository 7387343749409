.site-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $color__background-header;
    color: $color__text-header;
    box-shadow: $box-shadow__standard;
    z-index: zindex(mainNav);

    .no-js & {
        @include bp-down(lg) {   
            position: relative;
            box-shadow: none;
        }
    }
}

.site-header-content {
    position: relative;
    display: flex;
    justify-content: space-between;
    max-width: $size__site-nav-max-width;
    margin: 0 auto;
}

.site-branding {
    position: absolute;
    left: 0;
    top: 0;
    z-index: zindex(mainNav) + 1;

    @include bp-down(260px) {
        display: none;
    }

    a {
        color: inherit;
        text-decoration: none;

        &:visited {
            color: inherit;
        }
    }

    .site-title {
        margin-top: 0;
        margin-bottom: 0;
        padding: .6rem .3rem;
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.2em;
        display: none;
    }

    .site-logo {
        width: 40vw;
        min-width: rem(140);
        max-width: rem(250);
        height: auto;
    }
}
