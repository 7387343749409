.headline,
.item-page h2,
.content-component h2,
.content-category h2,
#content h2 {
    font-family: 'Tangerine', $font__headings;
    font-size: 2.5em;
    font-weight: 300;
    // word-wrap: break-word;
    margin-bottom: 0;
}

.item-page {
    padding-bottom: rem(30);
}

p.headline {
    font-size: 2em;
}

.item-page {
    font-size: 1.1em;
    
    p {
        line-height: 1.67;
    }
}

#pathway {
    @include accent-line();
}
