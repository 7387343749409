p,
a,
h1,
h2,
h3,
h4,
h5,
h6 {
    // @include word-wrap();
}

p {
    margin-top: 0.5em;
    margin-bottom: 1em;
    letter-spacing: .005em;
}

dfn, cite, em, i {
	font-style: italic;
}

blockquote {
	margin: 0 1.5em;
}

address {
	margin: 0 0 1.5em;
}

kbd, tt, var {
	font-family: $font__code;
	@include font-size(0.9375);
}

abbr, acronym {
	border-bottom: 1px dotted $color__border-abbr;
	cursor: help;
}

mark, ins {
	background: $color__background-ins;
	text-decoration: none;
}

big {
	font-size: 125%;
}
