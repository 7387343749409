.js .site-navigation {
    @include bp-down($breakpoint__main-menu-expand) {
        .parent>ul {
            display: none;
        }
    }
}

// styles to make the menu accessible without Javascript
.no-js {

    .nav-toggle-icon,
    .dropdown-toggle {
        display: none; // hide the toggle buttons if no JS is available
    }

    .site-navigation .joomla-nav {
        position: relative;
        right: unset;
        bottom: unset;
        margin: 0;
        width: 100%;
    }
}

.site-navigation {
    display: inline-block;
    margin: 0;
    margin-left: auto;

    .moduletable_menu {
        font-size: 1rem;
        padding: 0;
        margin: 0;
    }

    ul {
        list-style-type: none !important;
    }

    .joomla-nav {
        margin: 0;

        @include bp-down($breakpoint__main-menu-expand) {
            position: fixed;
            top: 0;
            bottom: 0;
            right: -100%;
            width: 55%;
            margin: rem(58) 0 0;
            background-color: $color__background-header;
            overflow-y: auto;
        }

        @include bp-down(sm) {
            width: 100%;
            padding-top: rem(60);
        }

        padding: 0;
        display: flex;
        justify-content: flex-start;
        flex-flow: column nowrap;
        max-width: $size__mobile_site-nav-max-width;
        z-index: zindex(mainNav);
        transition: right .6s ease-in-out;

        li {
            position: relative;
            margin: 0;
            padding: 0;

            a {
                display: inline-block;
                position: relative;
                width: 100%;
                padding: 1rem;
                font-family: $font__headings;
                font-size: 1.3em;
                border: 0;
                background-color: transparent;
                color: inherit;
                transition: all .3s ease-in-out;
                word-wrap: normal;
                word-break: normal;

                &::before,
                &::after {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 4px;
                    content: "";
                    background-color: $color__link-hover;
                }

                &::after {
                    transform: scaleX(0);
                    transform-origin: right;
                    transition: transform .3s;
                }

                &::before {
                    background-color: $color__text-header;

                    @include bp-up($breakpoint__main-menu-expand) {
                        display: none;
                    }
                }

                &:hover,
                &:focus {
                    &::after {
                        transform: scaleX(1);
                        transform-origin: left;
                    }
                }
            }
        }

        .parent a {
            padding-right: 2.5em;
        }

        @include bp-up($breakpoint__main-menu-expand) {
            flex-flow: row wrap;
            margin: 0 auto;

            li {
                border-bottom: 0 !important;

                a {
                    margin-bottom: 0;
                }
            }
        }
    }

    .parent>ul {
        margin: 0;
        padding: 0;
        background: darken($color__background-header, 5%);

        @include bp-up($breakpoint__main-menu-expand) {
            background: $color__background-header;
        }

        li a {
            padding-left: 1.4em;
            background: transparent;

            &::before {
                padding-right: .3em;
            }
        }
    }

    .parent>ul .parent>ul {
        background: lighten($color__background-header, 10%);
    }

    &.toggled-on {
        .joomla-nav {
            right: 0;
            transition: right 1.2s cubic-bezier(0.19, 1, 0.22, 1) .4s;
        }
    }
}

@include bp-up($breakpoint__main-menu-expand) {
    .site-navigation {
        width: 73%;
        max-width: none;
        background: $color__background-header;

        .joomla-nav {
            flex-flow: row nowrap;
            justify-content: space-between;
            max-width: none;
            padding: 0;

            li,
            .parent {
                margin-bottom: 0;

                &>a {
                    font-size: 1em;
                    padding: 1em 0;
                }
            }
        }

        .joomla-nav {
            max-width: $size__site-nav-max-width;

            &>li {
                margin-right: 1em;

                &:last-child {
                    margin-right: 0;

                    // align the right-most menu item's submenu
                    // to the right side of the screen
                    >ul {
                        right: 0;
                    }
                }
            }
        }

        .parent>ul {
            display: block !important;
            position: absolute;
            min-width: 100%;
            border-radius: 0;
            box-shadow: $box-shadow__standard;
            opacity: 0;
            visibility: hidden;
            transition: opacity .7s $std-easing .2s, visibility .7s $std-easing .2s;


            li a {
                padding-left: 1em;

                &::before {
                    content: none;
                }
            }
        }

        .parent:hover,
        .parent:focus {

            &>ul {
                opacity: 1;
                visibility: visible;
                transition: opacity .5s $std-easing, visibility .5s $std-easing;
            }
        }

        .dropdown-toggle {
            display: none;
        }
    }
}

.nav-toggle {
    display: inline-block;
    position: relative;
    margin: 0 auto;
    padding: .7em 0 .7em $padding__site;
    color: $color__text-header;
    background: inherit;
    border: 0;
    cursor: pointer;
    transition: all .3s ease-in-out;

    @include bp-up($breakpoint__main-menu-expand) {
        display: none;
    }
}

.nav-toggle-icon {
    position: relative;
    display: block;
    padding: .3em 0;
    height: rem(36);
    width: rem(36);
    transition: .3s;
    user-select: none;

    &>span {
        position: absolute;
        display: block;
        background: $color__text-header;
        width: 100%;
        height: rem(3);
        margin: rem(3) auto;
        border-radius: 10px;

        &:nth-of-type(1) {
            top: 2px;
            transition: top .3s ease .3s, transform .3s ease-out .1s;
        }

        &:nth-of-type(2) {
            top: 14px;
            transition: ease .3s .3s;
        }

        &:nth-of-type(3) {
            top: 26px;
            transition: top .3s ease .3s, transform .3s ease-out .1s;
        }
    }

    .site-navigation.toggled-on & {

        transition: transform .3s ease .5s;
        transform: rotate(45deg);

        &>span {

            &:nth-of-type(1) {
                top: 14px;
                transform: rotate(0deg);
                transition: top .3s ease .1s, transform .3s ease-out .5s;
            }

            &:nth-of-type(2) {
                opacity: 0;
            }

            &:nth-of-type(3) {
                top: 14px;
                transform: rotate(90deg);
                transition: top .3s ease .1s, transform .3s ease-out .7s;
            }
        }
    }
}

.dropdown-toggle {
    position: absolute;
    right: 0;
    width: 2.5em;
    padding: 1rem;
    font-size: 1.3rem;
    border: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background: inherit;
    color: $color__text-header;
    line-height: 1.5em;
    cursor: pointer;
    transition: background-color .3s $std-easing;

    &:hover,
    &:focus {
        background: $color__link-hover;
    }

    &.toggled-on {
        background: darken($color__background-header, 5%);
    }

    @include bp-up(sm) {
        width: 3.5rem;
    }
}

.menu-open {
    overflow: hidden;

    .site-navigation__overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .5);
        z-index: zindex(overlay);
    }
}
