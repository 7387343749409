html {
	box-sizing: border-box;
}

*,
*:before,
*:after { /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
	box-sizing: inherit;
}

hr {
	border: 0;
    margin-bottom: 1.5em;
    background-color: transparent;
    height: 33px;
    background-image: url('assets/images/hr.jpg');
    background-repeat: no-repeat;
    background-position: center center;
}

*::selection {
    background: $color__link-hover;
    color: #fff;
}

@import "lists";
@import "tables";
@import "code";
@import "blockquotes";
@import "sticky-icon";
