$font__main: 'Open Sans', 'Trebuchet MS', 'Lucida Grande', Verdana, Arial, sans-serif;
$font__headings: 'Open Sans', 'Trebuchet MS', 'Lucida Grande', Verdana, Arial, sans-serif;
$font__code: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
$font__pre: "Courier 10 Pitch", Courier, monospace;
$font__line-height-body: 1.5;
$font__line-height-pre: 1.6;

// The default browser font size but without the px unit 
// (used for calculating the rem value from a pixel value)
$browser-context: 16;
