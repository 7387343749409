// News Module

.moduletable_news {
    padding: .5rem;
    margin-bottom: 2.5rem;
    // background: $color__background-news;
    // color: $color__text-news;

    .newsflash-horiz_news {
        padding: 0;
        margin-bottom: .5em;
        list-style-type: none;

        li {
            position: relative;
            padding: 0 0 0 2em;
            margin: 0 0 1.5em 0;

            &::before {
                position: absolute;
                content: '';
                left: 0;
                top: 0;
                height: 1.5em;
                width: 1.5em;
                background-image: url('../images/baseline-expand_more-24px-black.svg');
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                transform: rotate(270deg);
            }
        }
    }

    .article-separator {
        display: none;
    }

    .newsflash_news p + p {
        padding-top: .5em;
        margin-top: 0;
        border-top: 1px solid $color__text-news;
    }
}


// News Page
.items-leading {
    margin-bottom: 0;
}

.items-row .item:first-of-type .page-header h2 {
    margin-top: 0;
}
